// Dependency
import React from "react"

// Template
const NotFound = () => {
  return (
    <div className="error-page">
      {/* <h2 className="error-page__heading">{t("error.oops")}</h2>
      <p className="error-page__description">{t("error.content")}</p> */}
      <h2 className="error-page__heading">Oops</h2>
      <p className="error-page__description">
        We cant find the page you are looking for.
      </p>
    </div>
  )
}

export default NotFound
